/**
 * Calculates shift for scroll indicator items container
 */
export const getTransform = (props: {
  /**
   * Indicator items
   */
  items: Array<{
    isActive: boolean;
    isHidden: boolean;
    isSmall: boolean;
  }>;

  /**
   * Width of a single indicator item (can be pixels or rems)
   */
  itemWidth: number;
}): number => {
  let transformIndex = 0;

  props.items.some((item, index) => {
    const itemIsAtTheEnd = index === props.items.length - 2;

    // Do not shift
    if (!item.isHidden || itemIsAtTheEnd) {
      return true;
    }

    transformIndex = -(index + 1);

    return false;
  });

  return transformIndex * props.itemWidth;
};
