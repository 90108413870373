import React from 'react'
import cx from 'classnames'
import style from './style.module.css'
import { Text } from '../text'
import { useTestId } from '../../hooks'

export type FormLabelProps = {
  label?: string
  className?: string
  testId?: string
}

export const FormLabel = React.forwardRef<
  HTMLLabelElement,
  React.PropsWithChildren<FormLabelProps>
>(({ label, className, children, testId = '' }, containerRef) => {
  const { getTestId } = useTestId({
    prefix: 'form-label'
  })
  return (
    <label
      className={cx(style.label, className)}
      ref={containerRef}
      {...getTestId(testId)}
    >
      {!!label && (
        <div className={cx(style.label__text)} {...getTestId('label')}>
          <Text variant='body3'>{label}</Text>
        </div>
      )}
      {children}
    </label>
  )
})
