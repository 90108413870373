import { useEffect, useState } from 'react'

type Link =
  | {
      type: 'next' | 'prev' | 'break'
      onClick(e: React.MouseEvent): void
    }
  | {
      type: 'page'
      page: number
      isActive: boolean
      onClick(e: React.MouseEvent): void
    }

export const usePagination = (props: {
  pageCount: number
  pageRangeDisplayed: number
  marginPagesDisplayed: number
  disableInitialCallback: boolean
  initialPage?: number
  onChangePage?(props: { selected: number }): void
}) => {
  const {
    pageCount = 10,
    pageRangeDisplayed = 2,
    marginPagesDisplayed = 3,
    initialPage,
    disableInitialCallback,
    onChangePage
  } = props

  const [selected, setSelected] = useState(initialPage ?? 0)

  useEffect(() => {
    if (selected === initialPage) {
      return
    }
    setSelected(initialPage ?? 0)
  }, [initialPage])

  const callCallback = (selectedItem: number) => {
    if (
      typeof onChangePage !== 'undefined' &&
      typeof onChangePage === 'function'
    ) {
      onChangePage({ selected: selectedItem })
    }
  }

  const handlePageSelected = (newSelected: number, evt: React.MouseEvent) => {
    evt.preventDefault()

    if (selected === newSelected) return

    setSelected(newSelected)

    // Call the callback with the new selected item:
    callCallback(newSelected)
  }

  const handlePreviousPage = (evt: React.MouseEvent) => {
    evt.preventDefault()
    if (selected > 0) {
      handlePageSelected(selected - 1, evt)
    }
  }

  const getForwardJump = () => {
    const forwardJump = selected + pageRangeDisplayed
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump
  }

  const getBackwardJump = () => {
    const backwardJump = selected - pageRangeDisplayed
    return backwardJump < 0 ? 0 : backwardJump
  }

  const handleBreakClick = (index: number, evt: React.MouseEvent) => {
    evt.preventDefault()

    handlePageSelected(
      selected < index ? getForwardJump() : getBackwardJump(),
      evt
    )
  }

  const handleNextPage = (evt: React.MouseEvent) => {
    evt.preventDefault()
    if (selected < pageCount - 1) {
      handlePageSelected(selected + 1, evt)
    }
  }

  useEffect(() => {
    if (typeof initialPage !== 'undefined' && !disableInitialCallback) {
      callCallback(initialPage)
    }
  }, [initialPage, disableInitialCallback])

  const getPageElement = (index: number) => {
    return {
      type: 'page' as 'page',
      page: index + 1,
      onClick: handlePageSelected.bind(null, index),
      isActive: selected === index
    }
  }

  const getPagination = () => {
    const items: Array<Link> = []

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(getPageElement(index))
      }
    } else {
      let leftSide = pageRangeDisplayed / 2
      let rightSide = pageRangeDisplayed - leftSide

      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected
        leftSide = pageRangeDisplayed - rightSide
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected
        rightSide = pageRangeDisplayed - leftSide
      }

      let index
      let page
      let breakView
      const createPageView = (index: number) => getPageElement(index)

      for (index = 0; index < pageCount; index++) {
        page = index + 1

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index))
          continue
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (items[items.length - 1] !== breakView) {
          breakView = {
            type: 'break' as 'break',
            onClick: handleBreakClick.bind(null, index)
          }
          items.push(breakView)
        }
      }
    }

    return items
  }

  let items: Array<Link> = []

  if (selected !== 0) {
    items.push({
      type: 'prev',
      onClick: handlePreviousPage
    })
  }

  items = items.concat(getPagination())

  if (selected !== pageCount - 1) {
    items.push({
      type: 'next',
      onClick: handleNextPage
    })
  }

  return { items }
}
