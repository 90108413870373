import React from 'react'
import cx from 'classnames'
import style from './style.module.css'
import { IconLoader } from '../icons'
import { useTestId } from '../../hooks'

export type KnobProps = {
  variant?: 'primary' | 'secondary' | 'ghost' | 'shadow'
  color?:
    | 'brand-main'
    | 'black'
    | 'grey'
    | 'inverted'
    | 'black-10'
    | 'white'
    | 'error'
  size?: 'large' | 'regular' | 'small'
  icon: React.ComponentType
  isSquare?: boolean
  isLoading?: boolean
  testId?: string
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void
}

export const Knob: React.FunctionComponent<KnobProps> = ({
  variant = 'primary',
  color = 'brand-main',
  size = 'regular',
  icon: Icon,
  isSquare,
  isLoading,
  testId = '',
  onClick = () => {}
}) => {
  const { getTestId } = useTestId({
    prefix: 'knob'
  })
  function createRipple(event: React.MouseEvent<HTMLButtonElement>) {
    const button = event.currentTarget

    const circle = document.createElement('span')
    const diameter = Math.max(button.clientWidth, button.clientHeight)
    const radius = diameter / 2
    const buttonBox = button.getBoundingClientRect()

    circle.style.width = circle.style.height = `${diameter}px`
    circle.style.left = `${event.clientX - buttonBox.left - radius}px`
    circle.style.top = `${event.clientY - buttonBox.top - radius}px`
    circle.classList.add(style.ripple)

    const ripple = button.getElementsByClassName(style.ripple)[0]

    if (ripple) {
      ripple.remove()
    }

    button.appendChild(circle)
  }

  const _onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) {
      return
    }
    createRipple(e)
    onClick(e)
  }

  return (
    <button
      onClickCapture={_onClick}
      className={cx(
        style.button,
        style['button--' + variant],
        style['button--' + color],
        style['button--' + size],
        isSquare && style['button--square']
      )}
      {...getTestId(testId)}
    >
      <div
        className={cx(style.content, isLoading && style['content--hidden'])}
        {...getTestId('icon')}
      >
        <Icon />
      </div>
      <div
        className={cx(style.loader, isLoading && style['loader--visible'])}
        {...getTestId('loader')}
      >
        <IconLoader />
      </div>
    </button>
  )
}
