import React, { useState } from 'react'
import style from './style.module.css'
import cx from 'classnames'
import { Text } from '../text'
import { useErrorRendered } from '../../hooks/use-error-rendered'
import TextareaAutosize from 'react-textarea-autosize'

export type InputTextareaProps = {
  value?: string
  placeholder?: string
  label?: string
  description?: string
  color?: 'default' | 'success' | 'error'
  error?: string
  prefixIcon?: React.ComponentType<{ className: string }>
  onChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void
  rows?: number
  maxRows?: number
  onErrorRendered?(props: { top: number }): void
  autoFocus?: boolean
  header?: React.ReactNode
}

export const InputTextarea = React.forwardRef<HTMLTextAreaElement, InputTextareaProps>(
  (
    {
      placeholder,
      label,
      description,
      prefixIcon: PrefixIcon,
      error,
      color = 'default',
      onErrorRendered = () => null,
      onChange,
      value,
      rows = 4,
      maxRows = 4,
      autoFocus,
      header
    },
    ref
  ) => {
    const { containerRef } = useErrorRendered(!!error, onErrorRendered)
    const [isFocused, setIsFocused] = useState(false)
    if (error) {
      color = 'error'
    }

    return (
      <label
        className={cx(style.input, style['input--' + color])}
        ref={containerRef}
      >
        {!!label && (
          <div className={cx(style.input__label)}>
            <Text variant='body3'>{label}</Text>
          </div>
        )}

        <div className={style['input__container']}>
          {!!PrefixIcon && (
            <PrefixIcon className={style['input__prefix-icon']} />
          )}
          <div
            className={cx(
              style['input__content'],
              isFocused && style['input__content--focused']
            )}
          >
            {!!header && <div className={style['input__header']}>{header}</div>}

            <TextareaAutosize
              onFocus={() => {
                setIsFocused(true)
              }}
              onBlur={() => setIsFocused(false)}
              autoFocus={autoFocus}
              minRows={rows}
              maxRows={maxRows}
              ref={ref}
              value={value}
              placeholder={placeholder}
              className={cx(
                style.input__control,
                !!PrefixIcon && style['input__control--with-prefix-icon']
              )}
              onChange={onChange}
            />
          </div>
        </div>

        {!!description && !error && (
          <div className={cx(style.input__description)}>
            <Text variant='body3'>{description}</Text>
          </div>
        )}

        {!!error && (
          <div className={cx(style.input__description)}>
            <Text variant='body3'>{error}</Text>
          </div>
        )}
      </label>
    )
  }
)
