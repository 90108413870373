import { useEffect, useRef } from 'react'

function findPos(obj: any) {
  var curtop = 0
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop
    } while ((obj = obj.offsetParent))
  }

  return curtop
}

export const useErrorRendered = (
  isError: boolean,
  onErrorRendered: (props: { top: number }) => void
) => {
  const container = useRef<HTMLElement>()
  const containerRef = (ref: any) => {
    container.current = ref
  }

  useEffect(() => {
    if (isError && container.current) {
      onErrorRendered({
        top: findPos(container.current)
      })
    }
  }, [isError])

  return {
    containerRef
  }
}
