import { createUseStyles } from 'react-jss'

export type UseSpacingProps = {
  margin?: string
  padding?: string
}
export const useSpacing = createUseStyles<
  string,
  { padding?: string; margin?: string }
>({
  spacing: (props) => {
    return {
      ...getStyles('margin', props.margin),
      ...getStyles('padding', props.padding)
    }
  }
})

function getStyles(prop: string, value?: string) {
  if (!value) {
    return {}
  }

  const splitValue = value.split('_')

  if (splitValue.length === 1) {
    return {
      [prop]: splitValue[0]
    }
  }

  return {
    [`${prop}-${splitValue[0]}`]: splitValue[1]
  }
}
