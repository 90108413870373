import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import cx from 'classnames'
import style from './style.module.css'
import { usePagination } from './use-pagination'
import { getTransform } from './pagination/get-transform'

const Indicator: React.FunctionComponent<{
  currentIndex: number
  itemsCount: number
}> = ({ itemsCount, currentIndex }) => {
  const { items, isStatic } = usePagination({
    activeIndex: currentIndex,
    itemsCount,
    visibleItemsCount: 5
  })
  return (
    <div
      className={style.indicator}
      style={{
        width: 5 * 14 + 'px'
      }}
    >
      <div
        className={style.indicator__container}
        style={
          isStatic
            ? {}
            : {
                transform: `translateX(${getTransform({
                  items: items,
                  itemWidth: 14
                })}px)`
              }
        }
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={cx(
                style.indicator__item,
                item.isActive && style['indicator__item--active'],
                item.isHidden && style['indicator__item--hidden'],
                item.isSmall && style['indicator__item--small']
              )}
            />
          )
        })}
      </div>
    </div>
  )
}

export const ImageSlider: React.FunctionComponent = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const onChangeIndex = (index: number) => {
    setCurrentIndex(index)
  }

  const itemsCount = React.Children.count(children)

  return (
    <div className={style['image-slider']}>
      <SwipeableViews className={style.swipable} onChangeIndex={onChangeIndex}>
        {children}
      </SwipeableViews>
      {itemsCount > 1 && (
        <Indicator currentIndex={currentIndex} itemsCount={itemsCount} />
      )}
    </div>
  )
}
