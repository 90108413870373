import { IconProps } from '../types'
import React from 'react'

export const LineFavorite: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.88455 13.031L12 20L19.1154 13.031C20.3207 11.8505 21 10.2344 21 8.5473V8.15826C21 7.25554 20.7504 6.37039 20.2789 5.60062L20.234 5.52745C19.4979 4.32582 18.2629 3.5165 16.8673 3.32123C15.4195 3.11866 13.9597 3.59919 12.9153 4.62209L12 5.51852L11.0847 4.62209C10.0403 3.59919 8.58051 3.11866 7.13273 3.32123C5.73714 3.5165 4.50208 4.32583 3.76595 5.52745L3.72113 5.60062C3.24957 6.37039 3 7.25554 3 8.15826V8.5473C3 10.2344 3.67925 11.8505 4.88455 13.031Z'
      stroke='currentColor'
      stroke-width='1.6'
    />
  </svg>
)
