import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import cx from 'classnames'
import { Text } from '../text'

export const Tabs: React.FunctionComponent<{
  variant?: string
  items: Array<{ label: string; value: string }>
  value: string
  onChange?(value: string): void
}> = ({ items, value, onChange = () => null, variant }) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const onClickTab = (value: string) => {
    setCurrentValue(value)
    onChange(value)
  }

  return (
    <div className={cx(style.tabs, style['tabs--' + variant])}>
      {items.map((item) => (
        <div
          className={cx(
            style.tabs__tab,
            currentValue === item.value && style['tabs__tab--active']
          )}
          key={item.value}
          onClick={() => onClickTab(item.value)}
        >
          <Text variant='body2'>{item.label}</Text>
        </div>
      ))}
    </div>
  )
}
