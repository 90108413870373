import React, { useEffect, useState } from 'react'
import { Text } from '../text'
import style from './style.module.css'
import cx from 'classnames'
import { MdAdd, MdCheck } from 'react-icons/md'

export type CheckboxProps = {
  variant?: 'toggle' | 'tag' | 'default' | 'tag-simple'
  description?: string
  isChecked?: boolean
  isDisabled?: boolean
  onChange?(isChecked: boolean): void
}

type TemplateProps = {
  isChecked: boolean
  isDisabled: boolean
  description?: string
  onCheck(): void
}

const DefaultTemplate: React.FunctionComponent<TemplateProps> = ({
  isChecked,
  isDisabled,
  onCheck,
  ...props
}) => {
  return (
    <label
      className={cx(
        style.checkbox,
        isChecked && style['checkbox--checked'],
        isDisabled && style['checkbox--disabled']
      )}
      onClick={!isDisabled ? onCheck : undefined}
    >
      <input type='hidden' checked={isChecked} readOnly />
      <div className={style.checkbox__toggle}>
        <MdCheck className={style['checkbox__toggle-icon']} />
      </div>
      <Text variant='body2'>{props.children}</Text>
    </label>
  )
}

const ToggleTemplate: React.FunctionComponent<TemplateProps> = ({
  isChecked,
  onCheck,
  isDisabled,
  description,
  ...props
}) => {
  return (
    <div className={style['toggle-container']}>
      <label
        className={cx(
          style.toggle,
          isDisabled && style['toggle-switch--disabled']
        )}
        onClick={!isDisabled ? onCheck : undefined}
      >
        <input type='hidden' checked={isChecked} readOnly />
        {!!props.children && (
          <div className={style['toggle-switch__content']}>
            <Text variant='body2'>{props.children}</Text>
          </div>
        )}
        <div
          className={cx(
            style['toggle-switch'],
            isChecked && style['toggle-switch--checked']
          )}
        />
      </label>
      {!!description && (
        <Text variant='label' color={'black-50'} className={style.description}>
          {description}
        </Text>
      )}
    </div>
  )
}

const TagTemplate =
  ({ isIconVisible }: { isIconVisible: boolean }) =>
  ({
    isChecked,
    isDisabled,
    onCheck,
    ...props
  }: React.PropsWithChildren<TemplateProps>) => {
    return (
      <label
        className={cx(
          style.tag,
          isChecked && style['tag--checked'],
          isDisabled && style['tag--disabled']
        )}
        onClick={!isDisabled ? onCheck : undefined}
      >
        <input type='hidden' checked={isChecked} readOnly />
        <Text variant='body2'>{props.children}</Text>
        {isIconVisible && (
          <div className={style['tag__icon-container']}>
            <MdCheck
              className={cx(
                style.tag__icon,
                isChecked && style['tag__icon--active']
              )}
            />
            <MdAdd
              className={cx(
                style.tag__icon,
                !isChecked && style['tag__icon--active']
              )}
            />
          </div>
        )}
      </label>
    )
  }

const templatesMap: Record<string, React.ComponentType<TemplateProps>> = {
  default: DefaultTemplate,
  toggle: ToggleTemplate,
  tag: TagTemplate({ isIconVisible: true }),
  'tag-simple': TagTemplate({ isIconVisible: false })
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  variant = 'default',
  description,
  isChecked: isCheckedProp,
  isDisabled = false,
  onChange = () => null,
  children
}) => {
  const [isChecked, setIsChecked] = useState(isCheckedProp || false)

  useEffect(() => {
    setIsChecked(isCheckedProp || false)
  }, [isCheckedProp])

  const Template = templatesMap[variant]

  const _onCheck = () => {
    const newIsChecked = !isChecked

    setIsChecked(newIsChecked)
    onChange(newIsChecked)
  }

  return (
    <Template
      isChecked={isCheckedProp === undefined ? isChecked : isCheckedProp}
      onCheck={_onCheck}
      isDisabled={isDisabled}
      description={description}
    >
      {children}
    </Template>
  )
}
