import { IconProps } from '../types'
import React from 'react'

export const LineKeyboardArrowRight: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M9.5 7L14.5 12L9.5 17' stroke='currentColor' stroke-width='1.6' />
  </svg>
)
