import { IconProps } from '../types'
import React from 'react'

export const SolidDelete: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.14286 19.7778C5.14286 21 6.17143 22 7.42857 22H16.5714C17.8286 22 18.8571 21 18.8571 19.7778V6.44444H5.14286V19.7778ZM7.95429 11.8667L9.56571 10.3L12 12.6556L14.4229 10.3L16.0343 11.8667L13.6114 14.2222L16.0343 16.5778L14.4229 18.1444L12 15.7889L9.57714 18.1444L7.96571 16.5778L10.3886 14.2222L7.95429 11.8667ZM14.4229 3.11111V2H9.56571V3.11111H4V5.33333H20V3.11111H14.4229Z'
      fill='currentColor'
    />
  </svg>
)
