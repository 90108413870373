import React, { Fragment } from 'react'
import style from './style.module.css'
import { Text } from '../text'
import cx from 'classnames'
import { Loader } from './loader'
import { Knob } from '../knob'
import { LineFavorite, SolidFavorite } from '@autoprom/autoprom-icons'

export type CardListing2Props = {
  title: string
  description: React.ReactNode
  specs: Array<string>
  isFavorite?: boolean
  size?: 'big'
  badge?: React.ReactNode
  onPress?(): void
  onFavorite?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

export const CardListing2: React.FunctionComponent<CardListing2Props> & {
  Loader: typeof Loader
} = ({
  title,
  description,
  children,
  size,
  specs,
  onFavorite,
  isFavorite,
  badge,
  onPress = () => null
}) => {
  return (
    <div className={cx(style.card, style[`card--${size}`])} onClick={onPress}>
      <div className={style.card__content}>
        {!!badge && <div className={style.badge}>{badge}</div>}
        <div className={style.content}>{children}</div>
      </div>
      <div className={cx(style.card__title)}>
        <Text variant='body2'>{title}</Text>
        <div className={style.card__action}>
          {!!onFavorite && (
            <Knob
              onClick={onFavorite}
              icon={isFavorite ? SolidFavorite : LineFavorite}
              variant='ghost'
              size='small'
            />
          )}
        </div>
      </div>
      {specs.length && (
        <div className={style.card__specs}>
          {specs.map((spec, index) => (
            <Fragment key={index}>
              <Text variant='label' color='black-50'>
                {spec}
              </Text>
              {!!(specs.length - 1 > index) && (
                <Text variant='label' color='black-50'>
                  •
                </Text>
              )}
            </Fragment>
          ))}
        </div>
      )}
      <div className={style.card__description}>{description}</div>
    </div>
  )
}

CardListing2.Loader = Loader
