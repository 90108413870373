import { IconProps } from '../types'
import React from 'react'

export const LineEdit: React.FunctionComponent<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9476 5.55239L3 16.5V21H7.5L18.4476 10.0524M13.9476 5.55239L16.5 3L21 7.5L18.4476 10.0524M13.9476 5.55239L18.4476 10.0524'
        stroke='black'
        strokeWidth='1.6'
      />
    </svg>
  )
}
