import React from 'react'
import style from './style.module.css'
import { usePagination } from '../../hooks/use-pagination'
import { Knob } from '../knob'
import { Button } from '../button'
import { LineArrowForward2, LineArrowBack2 } from '@autoprom/autoprom-icons'

export type PaginationProps = {
  current: number
  total: number
  isMinimal?: boolean
  onChange?(selectedPage: number): void
}

export const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const { onChange = () => null } = props
  const { items } = usePagination({
    pageCount: props.total,
    pageRangeDisplayed: 2,
    marginPagesDisplayed: 1,
    initialPage: props.current,
    disableInitialCallback: false,
    onChangePage: (props) => {
      onChange(props.selected)
    }
  })
  return (
    <div className={style.pagination}>
      {items.map((item, index) => {
        if (item.type === 'prev') {
          return (
            <Knob
              icon={LineArrowBack2}
              color='grey'
              variant='secondary'
              onClick={item.onClick}
              key='prev'
            />
          )
        }
        if (item.type === 'next') {
          return (
            <Knob
              icon={LineArrowForward2}
              color='grey'
              variant='secondary'
              onClick={item.onClick}
              key='next'
            />
          )
        }

        if (item.type === 'break') {
          return props.isMinimal ? null : (
            <div className={style.page}>
              <Button
                color='grey'
                variant='ghost'
                isFullWidth
                onClick={item.onClick}
                key={index}
              >
                ...
              </Button>
            </div>
          )
        }

        return props.isMinimal &&
          !(item as { isActive: boolean }).isActive ? null : (
          <div className={style.page}>
            <Button
              color={
                props.isMinimal
                  ? 'black'
                  : (item as { isActive: boolean }).isActive
                  ? 'brand-main'
                  : 'grey'
              }
              variant={
                props.isMinimal
                  ? 'ghost'
                  : (item as { isActive: boolean }).isActive
                  ? 'secondary'
                  : 'ghost'
              }
              onClick={item.onClick}
              isFullWidth
            >
              {(item as { page: number }).page}
            </Button>
          </div>
        )
      })}
    </div>
  )
}
