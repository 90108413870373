import { IconProps } from '../types'
import React from 'react'

export const SolidFavorite: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 20L4.88455 13.031C3.67925 11.8505 3 10.2344 3 8.5473V8.15826C3 7.25554 3.24957 6.37039 3.72113 5.60062L3.76595 5.52745C4.50208 4.32583 5.73714 3.5165 7.13273 3.32123C8.58051 3.11866 10.0403 3.59919 11.0847 4.62209L12 5.51852L12.9153 4.62209C13.9597 3.59919 15.4195 3.11866 16.8673 3.32123C18.2629 3.5165 19.4979 4.32582 20.234 5.52745L20.2789 5.60062C20.7504 6.37038 21 7.25554 21 8.15826V8.5473C21 10.2344 20.3207 11.8505 19.1154 13.031L12 20Z'
      fill='currentColor'
      stroke='currentColor'
      stroke-width='2'
    />
  </svg>
)
