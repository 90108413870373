import React from 'react'
import cx from 'classnames'
import style from './style.module.css'
import { useTestId } from '../../hooks/use-test-id'
import { useSpacing, UseSpacingProps } from '../../hooks/use-spacing'
export const Text: React.FunctionComponent<
  {
    variant?:
      | 'h1'
      | 'headline2'
      | 'headline3'
      | 'title1'
      | 'title2'
      | 'title3'
      | 'body1'
      | 'body2'
      | 'body3'
      | 'callout1'
      | 'callout2'
      | 'label'
      | 'label2'
      | 'link'
      | 'footnote1'
    as?: string
    decoration?: 'link'
    className?: string
    bold?: boolean
    isCrossed?: boolean
    testId?: string
    color?:
      | 'brand-main'
      | 'brand-secondary'
      | 'black'
      | 'black-90'
      | 'black-80'
      | 'black-70'
      | 'black-60'
      | 'black-50'
      | 'black-40'
      | 'black-30'
      | 'black-20'
      | 'black-10'
      | 'black-5'
      | 'white'
      | 'grey'
      | 'error'
      | 'success'
  } & UseSpacingProps
> = ({
  as = 'div',
  variant = 'body2',
  testId = '',
  decoration,
  children,
  className,
  isCrossed,
  color,
  bold,
  margin,
  padding
}) => {
  const El: any = as
  const { getTestId } = useTestId({
    prefix: 'text'
  })
  const spacingClassNames = useSpacing({ margin, padding })
  return (
    <El
      className={cx(
        style[`variant-${variant}`],
        !!decoration && style[`decoration-${decoration}`],
        bold && style.bold,
        isCrossed && style.crossed,
        style[`color--${color}`],
        spacingClassNames.spacing,
        className
      )}
      {...getTestId(testId)}
    >
      {children}
    </El>
  )
}
