import { IconProps } from '../types'
import React from 'react'

export const LineFilters: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2 17.0001L10 17.0001M14 7.00009H22M16 17.0001H22M2 7.00009H8M8 5.41431V8.58588C8 8.8511 8.10536 9.10545 8.29289 9.29299C8.68342 9.68351 9.31658 9.68351 9.70711 9.29299C9.89464 9.10545 10 8.8511 10 8.58588V5.41431C10 5.14909 9.89464 4.89474 9.70711 4.7072C9.31658 4.31668 8.68342 4.31668 8.29289 4.7072C8.10536 4.89474 8 5.14909 8 5.41431ZM14 15.4143V18.5859C14 18.8511 14.1054 19.1055 14.2929 19.293C14.6834 19.6835 15.3166 19.6835 15.7071 19.293C15.8946 19.1055 16 18.8511 16 18.5859V15.4143C16 15.1491 15.8946 14.8947 15.7071 14.7072C15.3166 14.3167 14.6834 14.3167 14.2929 14.7072C14.1054 14.8947 14 15.1491 14 15.4143Z'
      stroke='currentColor'
      stroke-width='1.6'
    />
  </svg>
)
