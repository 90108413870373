import { IconProps } from '../types'
import React from 'react'

export const LineChecked: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21 3L8.78571 20L3 15.5926'
      stroke='currentColor'
      stroke-width='1.6'
    />
  </svg>
)
