import React from 'react'
import { Text } from '../text'
import style from './style.module.css'
import cx from 'classnames'
import { Badge } from '../badge'
import { useErrorRendered } from '../../hooks/use-error-rendered'
import { LineArrowForward2 } from '@autoprom/autoprom-icons'
import { useTestId } from '../../hooks/use-test-id'

export type MenuProps = {
  count?: number
  countColor?: 'success'
  icon?: React.ComponentType<{ className?: string }>
  iconColor?: 'black-30'
  isReadOnly?: boolean
  suffixIcon?: React.ComponentType<{ className?: string }>
  suffixIconColor?: 'success'
  isActive?: boolean
  variant?: 'title' | 'default'
  description?: string
  onClick?(): void
  prefix?: React.ReactNode
  error?: string
  testId?: string
  onErrorRendered?(props: { top: number }): void
}

export const Menu: React.FunctionComponent<MenuProps> & {
  Loader: React.FunctionComponent<{
    isWithPrefix?: boolean
  }>
} = ({
  icon: Icon,
  suffixIconColor,
  onClick = () => null,
  isReadOnly,
  prefix,
  iconColor,
  count,
  countColor,
  error,
  variant = 'default',
  description,
  onErrorRendered = () => null,
  testId = '',
  ...props
}) => {
  const { containerRef } = useErrorRendered(!!error, onErrorRendered)
  const { getTestId } = useTestId({
    prefix: 'menu'
  })
  const {
    suffixIcon: SuffixIcon = variant === 'default' ? LineArrowForward2 : null
  } = props
  return (
    <div
      className={cx(style.menu, props.isActive && style['menu--active'])}
      onClick={isReadOnly ? undefined : onClick}
      ref={containerRef}
      {...getTestId(testId)}
    >
      {!!Icon && (
        <Icon
          className={cx(
            style.menu__icon,
            !!iconColor && style['menu__icon--color-' + iconColor]
          )}
        />
      )}
      {prefix && <div className={style.menu__prefix}>{prefix}</div>}
      <div className={style.menu__content}>
        <Text
          variant={variant === 'title' ? 'title3' : 'body1'}
          className={style.menu__content}
          testId={getTestId('title')['data-test']}
        >
          {props.children}
        </Text>
        {!!description && (
          <Text variant='label' color='black-50'>
            {description}
          </Text>
        )}
      </div>
      <div className={style.suffix}>
        {!!error && (
          <div>
            <Text variant='label' color='error' className={style.menu__error}>
              {error}
            </Text>
          </div>
        )}
        {!!count && (
          <div className={cx(style.menu__badge)}>
            <Badge color={countColor}>{count}</Badge>
          </div>
        )}

        {SuffixIcon && (
          <SuffixIcon
            className={cx(
              style['menu__suffix-icon'],
              style['menu__suffix-icon--color-' + suffixIconColor]
            )}
          />
        )}
      </div>
    </div>
  )
}

Menu.Loader = ({ isWithPrefix }) => {
  return (
    <div className={style.menu}>
      {isWithPrefix && (
        <div
          className={cx(
            style.menu__prefix,
            style.animation,
            style.loader__prefix
          )}
        />
      )}
      <div className={style.loader__content}>
        <div className={cx(style.animation, style.loader__title)} />
        <div className={cx(style.animation, style.loader__description)} />
      </div>
    </div>
  )
}
