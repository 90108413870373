/**
 * Returns first and last visible indicators indexes
 */
export const getVisibleIndexes = (
  state: {
    /**
     * Indicator items count
     */
    itemsCount: number

    /**
     * Visible indicator items count
     */
    visibleItemsCount: number

    /**
     * Active item index (starting from 0)
     */
    activeIndex: number
  } & {
    /**
     * First visible indicator item index
     */
    firstVisibleItemIndex: number

    /**
     * Last visible indicator item index
     */
    lastVisibleItemIndex: number
  }
): {
  firstVisibleItemIndex: number
  lastVisibleItemIndex: number
} => {
  switch (true) {
    // In case for first item being active
    case state.activeIndex === 0:
      return {
        firstVisibleItemIndex: 0,
        lastVisibleItemIndex: state.visibleItemsCount - 1
      }
    // In case active item is being the last item
    case state.activeIndex === state.itemsCount - 1:
      return {
        firstVisibleItemIndex: state.activeIndex + 1 - state.visibleItemsCount,
        lastVisibleItemIndex: state.activeIndex
      }
    // In case of active item is not visible and is on right
    case state.activeIndex <= state.firstVisibleItemIndex:
      return {
        firstVisibleItemIndex: state.activeIndex - 1,
        lastVisibleItemIndex: state.activeIndex + state.visibleItemsCount - 2
      }
    // In case of active item is not visible and is on left
    case state.activeIndex >= state.lastVisibleItemIndex:
      return {
        firstVisibleItemIndex: state.activeIndex + 2 - state.visibleItemsCount,
        lastVisibleItemIndex: state.activeIndex + 1
      }
    // Item is in between visible items
    default:
      return {
        firstVisibleItemIndex: state.firstVisibleItemIndex,
        lastVisibleItemIndex: state.lastVisibleItemIndex
      }
  }
}
