import React from 'react'
import { Text } from '../text'
import cx from 'classnames'
import style from './style.module.css'
import { IconProps } from '@autoprom/autoprom-icons/dist/types'

export type InputContainerProps = {
  prefixIcon?: React.ComponentType<IconProps>
  prefixLabel?: string
  description?: string
  error?: string
  isSuccess?: boolean
  isFocused?: boolean
  isLoading?: boolean
  color?: 'black-inverted' | 'black'
}

export const InputContainer = React.forwardRef<
  {
    error: React.Ref<HTMLDivElement>
  },
  React.PropsWithChildren<InputContainerProps>
>(
  (
    {
      prefixIcon: PrefixIcon,
      prefixLabel,
      description,
      error,
      children,
      isSuccess,
      isFocused,
      isLoading,
      color
    },
    ref: React.MutableRefObject<{
      error: React.Ref<HTMLDivElement>
    } | null>
  ) => {
    return (
      <React.Fragment>
        <div
          className={cx(
            style['input-container'],
            !!color && style['input-container--color-' + color],
            isFocused && style['input-container--focused'],
            !!error && style['input-container--error'],
            isSuccess && style['input-container--success']
          )}
        >
          {!!PrefixIcon && (
            <PrefixIcon className={style['input-container__prefix-icon']} />
          )}
          {!!prefixLabel && (
            <Text
              className={style['input-container__prefix-label']}
              color='black-40'
            >
              {prefixLabel}
            </Text>
          )}
          {children}
          {!!isLoading && (
            <div className={style.loader}>
              <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                x='0px'
                y='0px'
                viewBox='0 0 100 100'
                enableBackground='new 0 0 0 0'
                xmlSpace='preserve'
              >
                <path
                  fill='currentColor'
                  d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
                >
                  <animateTransform
                    attributeName='transform'
                    attributeType='XML'
                    type='rotate'
                    dur='1s'
                    from='0 50 50'
                    to='360 50 50'
                    repeatCount='indefinite'
                  />
                </path>
              </svg>
            </div>
          )}
        </div>

        {!!description && !error && (
          <Text
            variant='label'
            color={isSuccess ? 'success' : 'black-50'}
            className={style['input-container__description']}
          >
            {description}
          </Text>
        )}

        {!!error && (
          <div ref={ref?.current?.error}>
            <Text
              className={style['input-container__description']}
              variant='label'
              color='error'
            >
              {error}
            </Text>
          </div>
        )}
      </React.Fragment>
    )
  }
)
