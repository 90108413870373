import cx from 'classnames'
import React from 'react'
import style from './style.module.css'

export const Loader = () => {
  return (
    <div className={cx(style['loader'])}>
      <div className={cx(style['loader__content'], style['animation'])}>
        <div className={cx(style['loader__badge'])}></div>
      </div>
      <div className={style['loader__title']}>
        <div className={cx(style['title-loader'], style['animation'])}></div>
        <div className={cx(style['buttons'])}>
          <div className={cx(style['button-loader'], style['animation'])}></div>
          <div className={cx(style['button-loader'], style['animation'])}></div>
        </div>
      </div>
      <div className={style['loader__description']}>
        <div
          className={cx(style['description--big'], style['animation'])}
        ></div>
        <div className={cx(style['description'], style['animation'])}></div>
        <div
          className={cx(style['description--small'], style['animation'])}
        ></div>
        <div className={cx(style['description'], style['animation'])}></div>
      </div>
      <div className={style['loader__price']}>
        <div className={cx(style['description'], style['animation'])}></div>
      </div>
    </div>
  )
}
