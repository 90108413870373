import React, { useState } from 'react'
import style from './style.module.css'
import cx from 'classnames'
import { Text } from '../text'

export type InputPasswordProps = {
  placeholder?: string
  label?: string
  description?: string
  error?: string
  color?: 'default' | 'success' | 'error'
  prefixIcon?: React.ComponentType<{ className: string }>
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
}

export const InputPassword = React.forwardRef<
  HTMLInputElement,
  InputPasswordProps
>(
  (
    {
      placeholder,
      label,
      description,
      error,
      prefixIcon: PrefixIcon,
      color = !!error ? 'error' : 'default',
      onChange
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(false)
    return (
      <label className={cx(style.input, style['input--' + color])}>
        {!!label && (
          <div className={cx(style.input__label)}>
            <Text variant='body3'>{label}</Text>
          </div>
        )}
        <div className={style['input__container']}>
          {!!PrefixIcon && (
            <PrefixIcon className={style['input__prefix-icon']} />
          )}

          <input
            ref={ref}
            type={isVisible ? 'text' : 'password'}
            placeholder={placeholder}
            className={cx(
              style.input__control,
              !!PrefixIcon && style['input__control--with-prefix-icon']
            )}
            onChange={onChange}
          />

          <button
            type='button'
            onClick={() => setIsVisible(!isVisible)}
            className={style['visibility-button']}
          >
            {isVisible ? 'Hide' : 'Show'}
          </button>
        </div>

        {(!!description || !!error) && (
          <div className={cx(style.input__description)}>
            <Text variant='body3'>{error || description}</Text>
          </div>
        )}
      </label>
    )
  }
)
