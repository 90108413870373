import { IconProps } from '../types'
import React from 'react'

export const LineArrowBack: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22 12H3M3 12L11.5172 3M3 12L11.5172 21'
      stroke='currentColor'
      strokeWidth='1.6'
    />
  </svg>
)
