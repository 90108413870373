import { useEffect, useState } from 'react'
import { getItems } from './pagination/get-items'
import { getVisibleIndexes } from './pagination/get-visible-indexes'

export const usePagination = (props: {
  activeIndex: number
  itemsCount: number
  visibleItemsCount: number
}) => {
  const [state, setState] = useState(
    getVisibleIndexes({
      firstVisibleItemIndex: 0,
      lastVisibleItemIndex: 0,
      activeIndex: props.activeIndex,
      itemsCount: props.itemsCount,
      visibleItemsCount: props.visibleItemsCount
    })
  )

  useEffect(() => {
    setState((prevState) =>
      getVisibleIndexes({
        ...prevState,
        ...props
      })
    )
  }, [props.activeIndex, props.itemsCount, props.visibleItemsCount])

  return {
    items: getItems({
      ...state,
      activeIndex: props.activeIndex,
      itemsCount: props.itemsCount
    }),
    isStatic: props.itemsCount <= props.visibleItemsCount
  }
}
