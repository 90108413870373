import { IconProps } from '../types'
import React from 'react'

export const LineLocationOn: React.FunctionComponent<IconProps> = ({
  className
}) => (
  <svg
    className={className}
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 11V22'
      stroke='currentColor'
      stroke-width='1.6'
    />
  </svg>
)
