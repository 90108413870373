import React from 'react'
import style from './style.module.css'
import cx from 'classnames'

const Placeholder = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='27'
        cy='27'
        r='26.75'
        fill='#F2F2F2'
        stroke='#F2F2F2'
        stroke-width='0.5'
      />
      <path
        d='M17.5137 36.4865C17.5611 33.3349 23.838 31.6086 27.0002 31.6086C30.1465 31.6086 36.4392 33.3349 36.4866 36.4865M27.0002 17.5135C29.6248 17.5135 31.7434 19.6357 31.7434 22.2647C31.7434 24.8936 29.6248 27.0158 27.0002 27.0158C24.3756 27.0158 22.2569 24.8936 22.2569 22.2647C22.2569 19.6357 24.3756 17.5135 27.0002 17.5135Z'
        stroke='#CCCCCC'
        stroke-width='2'
      />
    </svg>
  )
}

export type AvatarProps = {
  content?: React.ReactNode
  size?: 'default' | 'large'
}

export const Avatar: React.FunctionComponent<AvatarProps> = ({
  content,
  size = 'default'
}) => {
  return (
    <div className={cx(style.avatar, style['avatar--' + size])}>
      {!content ? (
        <Placeholder />
      ) : typeof content === 'string' ? (
        <img src={content} />
      ) : (
        content
      )}
    </div>
  )
}
