import React from 'react'
import style from './style.module.css'

export const IconLoader: React.FunctionComponent<{
  className?: string
}> = (props) => {
  return (
    <svg
      className={props.className}
      width='1em'
      height='1em'
      id='ew9q16HpvyD1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 34 34'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
    >
      <g transform='translate(17 17)' className={style['animated-path']}>
        <path
          id='ew9q16HpvyD2'
          d='M0-16v8M0 8v8m8-29.856L4-6.928M-8 13.856l4-6.928M13.856-8L6.928-4M-13.856 8l6.928-4M16 0H8m-24 0h8m21.856 8L6.928 4M-13.856-8l6.928 4M8 13.856L4 6.928M-8-13.856l4 6.928'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </g>
    </svg>
  )
}
