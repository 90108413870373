/**
 * Creates a list of scroll indicator items based on options
 */
export const getItems = (
  options: {
    /**
     * First visible indicator item index
     */
    firstVisibleItemIndex: number

    /**
     * Last visible indicator item index
     */
    lastVisibleItemIndex: number
  } & {
    /**
     * Active gallery index
     */
    activeIndex: number

    /**
     * Indicator items count
     */
    itemsCount: number
    /**
     * Should indicator items be reversed
     */
    isReversed?: boolean
  }
): {
  /**
   * Item is active
   */
  isActive: boolean

  /**
   * Item is small
   */
  isSmall: boolean

  /**
   * Item is hidden
   */
  isHidden: boolean
}[] => {
  const result = Array.from(Array(options.itemsCount).keys()).map((index) => {
    const item = {
      isActive: false,
      isSmall: false,
      isHidden: false
    }

    switch (true) {
      case index === options.activeIndex:
        item.isActive = true
        break
      case options.lastVisibleItemIndex - options.firstVisibleItemIndex >=
        options.itemsCount - 1:
        break
      case index > options.lastVisibleItemIndex:
      case index < options.firstVisibleItemIndex:
        item.isHidden = true
        break
      case index === options.lastVisibleItemIndex:
      case index === options.firstVisibleItemIndex:
        item.isSmall = true
        break
    }

    return item
  })

  return options.isReversed ? result.reverse() : result
}
