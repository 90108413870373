import React from 'react'
import style from './style.module.css'
import { Text } from '../text'
import cx from 'classnames'

export type BadgeProps = {
  color?: 'error' | 'success' | 'gradient-primary'
  size?: 'small' | 'default'
}

export const Badge: React.FunctionComponent<BadgeProps> = ({
  children,
  color,
  size = 'default'
}) => {
  return (
    <div
      className={cx(
        style.badge,
        style['badge--' + color],
        style['badge--size-' + size]
      )}
    >
      <Text color='white' variant='label'>
        {size === 'small' ? null : children}
      </Text>
    </div>
  )
}
