import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import cx from 'classnames'
import { useErrorRendered } from '../../hooks/use-error-rendered'
import { FormLabel } from '../form-label'
import { InputContainer } from '../input-container'
import { IconProps } from '@autoprom/autoprom-icons/dist/types'
import { useTestId } from '../../hooks'

export * from './formatters/number'

export type InputProps = {
  placeholder?: string
  label?: string
  description?: string
  type?: 'text' | 'tel' | 'number' | 'email'
  color?: 'default' | 'success' | 'error' | 'black-inverted' | 'black'
  isReadonly?: boolean
  value?: string
  prefixIcon?: React.ComponentType<IconProps>
  prefixLabel?: string
  isLoading?: boolean
  error?: string
  testId?: string
  onFocus?(): void
  onBlur?(): void
  /**
   * Formatting function. Will format input value when input is blurred
   */
  formatter?(value: string): string
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  onErrorRendered?(props: { top: number }): void
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      label,
      description,
      value: _value = '',
      prefixIcon: PrefixIcon,
      prefixLabel,
      type = 'text',
      error,
      color = 'default',
      isLoading = false,
      onChange: _onChange = () => null,
      isReadonly,
      onErrorRendered = () => null,
      inputProps = {},
      formatter = (value) => value,
      testId = '',
      onFocus: _onFocus = () => {},
      onBlur: _onBlur = () => {}
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const { placeholder: pl, ...inpProps } = inputProps
    const { containerRef } = useErrorRendered(!!error, onErrorRendered)
    const [value, setValue] = useState(_value)
    const [formattedValue, setFormattedValue] = useState(formatter(value))
    const { getTestId } = useTestId({
      prefix: 'input'
    })

    useEffect(() => {
      if (value !== _value) {
        setValue(_value)
      }
    }, [_value])

    useEffect(() => {
      setFormattedValue(formatter(value))
    }, [value])

    const onBlur = () => {
      setIsFocused(false)
      _onBlur()
    }
    const onFocus = () => {
      if (isReadonly) {
        return
      }
      setIsFocused(true)
      _onFocus()
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
      _onChange(e)
    }

    return (
      <FormLabel
        label={label}
        className={style['input--' + color]}
        ref={containerRef}
        testId={getTestId(testId)['data-test']}
      >
        <InputContainer
          prefixIcon={PrefixIcon}
          prefixLabel={prefixLabel}
          error={error}
          description={description}
          isSuccess={color === 'success'}
          isFocused={isFocused}
          color={color as 'black-inverted'}
          isLoading={isLoading}
        >
          <input
            readOnly={isReadonly}
            ref={ref}
            type={type}
            value={isFocused ? value : formattedValue}
            className={cx(
              style.input__control,
              !!color && style['input__control--color-' + color],
              !!error && style['input__control--color-error'],
              !!PrefixIcon && style['input__control--with-prefix-icon']
            )}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            {...getTestId('input-element')}
            {...inpProps}
          />
        </InputContainer>
      </FormLabel>
    )
  }
)
