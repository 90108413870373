import React from 'react'
import cx from 'classnames'
import style from './style.module.css'
import { IconLoadingInfinity } from '../icons/loading-infinity'
import { IconProps } from '@autoprom/autoprom-icons/dist/types'
import { useTestId } from '../../hooks/use-test-id'
import { useSpacing, UseSpacingProps } from '../../hooks/use-spacing'

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'ghost'
  color?:
    | 'brand-main'
    | 'black'
    | 'grey'
    | 'brand-secondary'
    | 'white'
    | 'error'
  size?: 'small' | 'regular' | 'large'
  textColor?: 'brand-3'
  suffixIcon?: React.ComponentType<IconProps>
  prefixIcon?: React.ComponentType<IconProps>
  isFullWidth?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void
  hasMinWidth?: boolean
  testId?: string
} & UseSpacingProps

export const Button: React.FunctionComponent<ButtonProps> = ({
  variant = 'primary',
  color = 'brand-main',
  size = 'regular',
  margin,
  padding,
  type,
  textColor = '',
  onClick = () => {},
  isFullWidth,
  isLoading,
  isDisabled,
  prefixIcon: PrefixIcon,
  suffixIcon: SuffixIcon,
  hasMinWidth = true,
  testId = '',
  ...props
}) => {
  const { getTestId } = useTestId({
    prefix: 'button'
  })
  const spacingClassNames = useSpacing({ margin, padding })
  function createRipple(event: React.MouseEvent<HTMLButtonElement>) {
    const button = event.currentTarget

    const circle = document.createElement('span')
    const diameter = Math.max(button.clientWidth, button.clientHeight)
    const radius = diameter / 2
    const buttonBox = button.getBoundingClientRect()

    circle.style.zIndex = '0'
    circle.style.width = circle.style.height = `${diameter}px`
    circle.style.left = `${event.clientX - buttonBox.left - radius}px`
    circle.style.top = `${event.clientY - buttonBox.top - radius}px`
    circle.classList.add(style.ripple)

    const ripple = button.getElementsByClassName(style.ripple)[0]

    if (ripple) {
      ripple.remove()
    }

    button.appendChild(circle)
  }

  const _onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading || isDisabled) {
      return
    }
    createRipple(e)
    onClick(e)
  }

  return (
    <button
      onClick={_onClick}
      type={type}
      className={cx(
        style.button,
        style['button--' + variant],
        style['button--' + color],
        style['button--' + size],
        style['button--text-' + textColor],
        hasMinWidth && style['button--has-min-width'],
        isFullWidth && style['button--full-width'],
        !!SuffixIcon && style['button--with-suffix-icon'],
        isLoading && style['button--is-loading'],
        isDisabled && style['button--is-disabled'],
        spacingClassNames.spacing
      )}
      {...getTestId(testId)}
    >
      <div className={style.button__content}>
        {!!PrefixIcon && (
          <PrefixIcon className={style['button__icon--prefix']} />
        )}

        <div>{props.children}</div>
        {!!SuffixIcon && <SuffixIcon className={style.button__icon} />}
      </div>
      <div className={cx(style.button__loader)}>
        <IconLoadingInfinity className={style['button__loader-icon']} />
      </div>
    </button>
  )
}
