import React from 'react'
type ThemeValues = {
  isTestIdsEnabled: boolean
}

export const ThemeContext = React.createContext<ThemeValues>({
  isTestIdsEnabled: false
})

export const Theme: React.FC<{
  values: ThemeValues
}> = (props) => {
  return (
    <ThemeContext.Provider value={props.values}>
      {props.children}
    </ThemeContext.Provider>
  )
}
