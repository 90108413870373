import { useContext } from 'react'
import { ThemeContext } from '../components'

type UseTestIdProps = {
  prefix?: string
}

export const useTestId = (props: UseTestIdProps) => {
  const themeContext = useContext(ThemeContext)

  const getTestId = (id: string) => {
    if (!themeContext.isTestIdsEnabled) {
      return {}
    }

    return {
      'data-test': [props.prefix, id].filter((v) => !!v).join('.')
    }
  }

  return {
    getTestId
  }
}
